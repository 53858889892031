.bg-daterangepicker {
    background-color: var(--bg-menu);
    box-shadow: var(--box-shadow);
    color: var(--text);
    border-color: var(--border-defaul);
    height: 38px;
}
.react-daterange-picker {
    width: 100%;
}
.react-daterange-picker__wrapper {
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0px 8px 0px 16px;
}
.react-daterange-picker__button:focus {
    outline: none;
}
.react-daterange-picker__button .react-daterange-picker__button__icon{
    stroke: var(--text);
}
.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: var(--secondary);
}
.react-daterange-picker__inputGroup {
    display: flex;
    justify-content: center;
    font-size: 0.45rem;
}
.react-daterange-picker__range-divider {
    font-size: 0.45rem;
    display: flex;
    align-items: center;
}
.react-calendar__tile--active {
    background: var(--secondary);
}
.react-calendar__tile--hasActive {
    background: var(--secondary-light);
}
.react-calendar__tile--hasActive:hover {
    background: var(--secondary-light);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--secondary-light);
}
.react-daterange-picker__inputGroup__input {
    color: var(--text);
}
@media (min-width: 576px) {
    .react-daterange-picker__inputGroup {
        font-size: 0.5rem;
    }
    .react-daterange-picker__range-divider {
        font-size: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .react-daterange-picker__inputGroup {
        font-size: 0.6rem;
    }
    .react-daterange-picker__range-divider {
        font-size: 0.6rem;
    }
}
@media (min-width: 1350px) {
    .react-daterange-picker__inputGroup {
        font-size: 0.8rem;
    }
    .react-daterange-picker__range-divider {
        font-size: 0.8rem;
    }
}
.react-calendar__navigation button {
    color: var(--text);
}
.react-calendar--selectRange .react-calendar__tile--hover{
    color: var(--gray);
}
.react-calendar__month-view__days__day--weekend{
    color: var(--red)!important;
}
.react-calendar button{
    color: var(--text);
}
.react-calendar{
    background-color: var(--bg-menu);
    border-color: var(--bg-menu)
}
.Dropdown-root {
    height: 100%;
}
.Dropdown-control {
    border: none;
    box-shadow: var(--box-shadow);
    padding-left: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: var(--bg-menu);
}
.Dropdown-control:hover {
    box-shadow: var(--box-shadow);
}
.Dropdown-placeholder {
    font-size: 0.8rem;
    color: var(--text);
    font-family: "Open Sans", sans-serif;
}
.Dropdown-menu {
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    background-color: var(--bg-menu);
}
.Dropdown-option {
    padding-left: 16px;
    color: var(--text);
}
.Dropdown-option:hover {
    background-color: var(--secondary);
    color: var(--bg-menu);
}
.Dropdown-option.is-selected {
    background-color: var(--secondary);
    color: var(--bg-menu);
}
.Dropdown-arrow {
    margin-top: 3px;
}
.devas-select {
    color: var(--text);
    font-size: 0.8rem;
}
.devas-select .devas-select__placeholder {
    color: var(--text);
    font-size: 0.8rem;
    outline: none;
}
.devas-select .devas-select__control--is-focused{
    border:none;
}
.devas-select .devas-select__control {
    border-radius: 0;
    border: none;
}

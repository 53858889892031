.myModal > .modal-dialog > .modal-content {
    background-color: var(--bg-menu);
    color: var(--text);
}
.comboChoice {
    border: 1px solid var(--gray);
}
.comboChoice:hover {
    cursor: pointer;
}
.comboChoiceSelected {
    border: 1px solid var(--primary);
}
.comboChoiceSelected:hover{
    cursor: pointer;
}